import uniq from "lodash/uniq";
import compareDesc from "date-fns/compareDesc";
import {
  TransistorApiEpisodesResults,
  TransistorEpisodeInterface
} from "../../../interfaces/podcasts";
import { SeasonTabLabel } from "../components/SeasonTabButtons";

interface InitialiseEpisodeStateParams {
  selectedEpisodes?: Array<TransistorEpisodeInterface>;
  staticEpisodes?: Array<TransistorEpisodeInterface>;
}

export const initialiseEpisodeState = ({
  selectedEpisodes,
  staticEpisodes
}: InitialiseEpisodeStateParams) => {
  if (selectedEpisodes) {
    return selectedEpisodes;
  }
  return staticEpisodes || [];
};

export const establishSeasons = (
  episodes: Array<TransistorEpisodeInterface>,
  displaySeasons: boolean,
  strictValue?: number
) => {
  if (strictValue && displaySeasons) {
    return strictValue;
  }

  return displaySeasons
    ? uniq(episodes.map((ep) => ep.attributes.season)).length || 1
    : -1;
};

export const handleEpisodeList = async (
  staticData: Array<TransistorEpisodeInterface>
) => {
  const response = await fetch("/.netlify/functions/get-episodes", {
    body: JSON.stringify({ staticData }),
    method: "POST"
  }).catch((err) => {
    throw new Error(err);
  });
  const { data }: TransistorApiEpisodesResults = await response?.json();
  return data;
};

export const sortAndFilterEpisodes = (
  episodesToFilter: TransistorEpisodeInterface[]
) =>
  episodesToFilter
    .sort((a, b) => {
      return compareDesc(
        new Date(a.attributes.published_at),
        new Date(b.attributes.published_at)
      );
    })
    .filter((ep) => ep.attributes.status === "published");

export const filterAndSortEpisodes = (
  eps: TransistorEpisodeInterface[],
  selectedSeason: SeasonTabLabel
) =>
  eps
    .filter((ep) => {
      return (
        ep.attributes.season === selectedSeason &&
        ep.attributes.status === "published"
      );
    })
    .sort((a, b) => Number(a.attributes.number) - Number(b.attributes.number));
