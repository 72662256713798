import React from "react";
import Img from "gatsby-image";
import { CTASectionImage } from "..";
import Figure from "../../Figure";
import { ctaSectionStyles as styles } from "../ctaSectionStyles";

export const hasImage = (image?: CTASectionImage) => {
  if (!image) return;
  if (image && React.isValidElement(image)) return true;
  if (image && "asset" in image && image.asset) return true;
  if (image && "childImageSharp" in image && image.childImageSharp) return true;
};

export const renderImage = (img?: CTASectionImage) => {
  if (img && React.isValidElement(img)) return img;
  if (img && "asset" in img) return <Figure node={img} styles={styles.image} />;
  if (img && "childImageSharp" in img && img.childImageSharp)
    return (
      <Img fluid={img.childImageSharp.fluid} fadeIn={false} loading="eager" />
    );
  return null;
};
