import { CSSObject } from "@emotion/core";
import isArray from "lodash/isArray";
import React, { CSSProperties } from "react";
import setTestId from "../../helpers/setTestId";
import { ChildImageSharp, CMSImage } from "../../interfaces/images";
import Button from "../Button";
import Heading, { HeadingLevel } from "../Heading";
import Inner from "../Inner";
import PortableText from "../PortableText";
import CTALink from "./components/CTALink";

import { ctaSectionStyles as styles } from "./ctaSectionStyles";
import { hasImage } from "./helpers/imageHelpers";
import { BodyPortableText } from "../../interfaces/sanity";
import { getLayoutMap } from "./helpers/getLayoutMap";
import CTAImage from "./components/CTAImage";

export interface CTAInterface {
  title?: string;
  href: string;
  target?: string;
  content: string;
}

export type CTASectionImage = ChildImageSharp | CMSImage | JSX.Element;
export type CTASectionLayout = "horizontal" | "vertical" | "blog" | "product";
export type CTASectionAlignment = "left" | "right";
export interface CTASectionProps {
  alignment?: CTASectionAlignment;
  layout?: CTASectionLayout;
  theme?: "light" | "dark";
  cta?: CTAInterface | null;
  title?: string | JSX.Element;
  content?: string | Array<BodyPortableText> | JSX.Element;
  image?: CTASectionImage;
  collapseBottomPadding?: boolean;
  collapseAllPadding?: boolean;
  cssStyles?: Array<CSSObject>;
  testId?: string;
  headingLevel?: HeadingLevel;
}

const CTASection: React.FC<CTASectionProps> = ({
  alignment = "left",
  theme = "dark",
  cssStyles = [],
  layout = "horizontal",
  cta,
  title,
  content,
  image,
  collapseBottomPadding,
  collapseAllPadding,
  testId,
  headingLevel = "h2"
}) => {
  const layoutMap = getLayoutMap(layout);
  return (
    <div
      css={[
        styles.container,
        styles[`container_${theme}`],
        collapseBottomPadding && styles.collapseBottomPadding,
        ...cssStyles
      ]}
      style={
        collapseAllPadding
          ? { ...(styles.collapseAllPadding as CSSProperties) }
          : {}
      }
      {...setTestId(testId)}
    >
      <Inner
        cssStyles={[collapseBottomPadding ? styles.collapseBottomPadding : {}]}
        collapseAll={collapseAllPadding}
      >
        <div
          css={[
            styles[`inner_${layoutMap}`],
            !hasImage(image)
              ? styles.noImage
              : styles[`${alignment}_${layoutMap}`],
            collapseBottomPadding && styles.collapseBottomPadding,
            collapseAllPadding && styles.collapseAllPadding
          ]}
        >
          <CTAImage
            image={image}
            layout={layout}
            cta={cta}
            alignment={alignment}
          />

          <div css={styles[`content_${layoutMap}`]}>
            {title && (
              <Heading
                headingLevel={headingLevel}
                styles={styles[`heading_${layoutMap}`]}
              >
                {title}
              </Heading>
            )}

            <div>
              {isArray(content) ? <PortableText blocks={content} /> : content}
            </div>
            {cta && layout !== "product" && (
              <Button
                type={theme === "dark" ? "primary" : "secondary"}
                cssStyles={styles.cta}
                asWrapper={true}
              >
                <CTALink cta={cta}>{cta.content}</CTALink>
              </Button>
            )}
          </div>
        </div>
      </Inner>
    </div>
  );
};

export default CTASection;
