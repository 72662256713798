import { CSSObject } from "@emotion/core";
import { EmotionStyles } from "../../interfaces/emotion";
import { breakpointMixins } from "../../styles/breakpoints";
import { colors } from "../../styles/colors";
import { textStyles } from "../../styles/type";
import { variables } from "../../styles/variables";

const { spacing } = variables;

const baseContent: CSSObject = {
  wordBreak: "break-word"
};

const imageVertical: CSSObject = {
  minWidth: 180,
  maxWidth: 180,
  textAlign: "center",
  marginBottom: spacing.large
};

const contentVertical: CSSObject = {
  ...textStyles.bodyM,
  ...baseContent,
  maxWidth: 400,
  marginBottom: spacing.medium
};

export const ctaSectionStyles: EmotionStyles = {
  container: {
    width: "100%",
    paddingTop: spacing.large,
    paddingBottom: spacing.large,
    [breakpointMixins.lg]: {
      paddingTop: `${spacing.raw.large * 3}rem`,
      paddingBottom: `${spacing.raw.large * 3}rem`
    }
  },
  container_light: {
    backgroundColor: colors.white
  },
  container_dark: {
    backgroundColor: colors.primary
  },
  collapseBottomPadding: {
    [breakpointMixins.md]: {
      paddingBottom: 0
    },
    [breakpointMixins.lg]: {
      paddingBottom: 0
    }
  },
  collapseAllPadding: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0
  },
  inner_horizontal: {
    [breakpointMixins.md]: {
      display: "grid",
      gridGap: spacing.large
    }
  },
  noImage: {
    display: "block",
    [breakpointMixins.md]: {
      padding: `0 ${spacing.large}`
    }
  },
  left_horizontal: {
    [breakpointMixins.md]: {
      gridTemplateColumns: "minMax(325px, 360px) minMax(auto, 1fr)"
    }
  },
  right_horizontal: {
    [breakpointMixins.md]: {
      gridTemplateColumns: "minMax(auto, 1fr) minMax(325px, 360px)"
    }
  },
  image_vertical: imageVertical,
  image_horizontal: {
    ...imageVertical,
    [breakpointMixins.md]: {
      maxWidth: 360,
      margin: 0,
      padding: `0 ${spacing.medium} 0 0`
    }
  },
  image_blog: {
    width: "100%",
    marginBottom: spacing.medium
  },
  image_left: {},
  image_right: {
    order: 1,
    padding: `0 0 0 ${spacing.medium}`
  },
  content_vertical: {
    ...contentVertical
  },
  content_horizontal: {
    ...contentVertical,
    [breakpointMixins.lg]: {
      ...textStyles.bodyL,
      maxWidth: 612
    }
  },
  heading_horizontal: {
    margin: `${spacing.medium} 0`,

    [breakpointMixins.lg]: {
      margin: `${spacing.small} 0 ${spacing.medium}`,
      ...textStyles.headingL
    }
  },
  heading_vertical: {
    margin: `${spacing.medium} 0`
  },
  heading_blog: {
    marginTop: 0,
    marginBottom: spacing.medium
  },
  cta: {
    display: "block",
    marginTop: spacing.large,

    "& a": {
      textDecoration: "none"
    },
    "& a:hover": {
      color: "inherit"
    }
  }
};
