import { usePlayer } from "../contexts/PlayerContextV2/PlayerContextV2";

const useIsPlayerLoading = (id: string) => {
  const {
    state: { currentPlaying, isBuffering }
  } = usePlayer();
  if (!currentPlaying || !currentPlaying.episode) return;

  return currentPlaying.episode.id === id && isBuffering;
};

export default useIsPlayerLoading;
