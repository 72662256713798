export const getUrlObject = (url: string) => {
  const wmdjRegex = /^https:\/\/whymumsdontjump\.com\//;
  const internalRegex = /^\/[a-zA-Z0-9_\/\-]*$/;
  const isWmdj = wmdjRegex.test(url);
  const isInternal = internalRegex.test(url);
  try {
    const urlObj = new URL(url);
    return {
      ...urlObj,
      href: isWmdj ? urlObj.pathname : url,
      isInternal: isWmdj
    };
  } catch (error) {
    return {
      href: url,
      isInternal
    };
  }
};
