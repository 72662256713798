import React from "react";
import { truncateText } from "../../../helpers/truncateText";
import { TransistorEpisodeAttributes } from "../../../interfaces/podcasts";
import { styles } from "../styles";

export type EpisodeDescriptionProps = Pick<
  TransistorEpisodeAttributes,
  "season" | "formatted_summary" | "description"
>;
const EpisodeDescription: React.FC<EpisodeDescriptionProps> = ({
  season,
  formatted_summary,
  description
}) => {
  const trimEncodedContent = (contentToTrim: string) => {
    return contentToTrim.split(/<br\s*\/?>/)[0];
  };
  switch (season) {
    case 1:
      return (
        <>
          {description ? (
            <article
              css={styles.main}
              dangerouslySetInnerHTML={{
                __html: trimEncodedContent(description)
              }}
            />
          ) : (
            <article css={styles.main}>{formatted_summary}</article>
          )}
        </>
      );
    case 2:
    case 3:
      return <article css={styles.main}>{formatted_summary}</article>;
    default:
      return (
        <article css={styles.main}>{truncateText(formatted_summary, 332)}</article>
      );
  }
};

export default EpisodeDescription;
