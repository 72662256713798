import React from "react";
import { ctaSectionStyles as styles } from "../ctaSectionStyles";
import { CTASectionProps } from "..";
import { renderImage } from "../helpers/imageHelpers";
import { Link } from "gatsby";
import { getLayoutMap } from "../helpers/getLayoutMap";
const CTAImage = ({
  layout = "horizontal",
  alignment,
  image,
  cta
}: Pick<CTASectionProps, "layout" | "alignment" | "image" | "cta">) => {
  const layoutMap = getLayoutMap(layout);
  if (!image) return null;

  if (layout === "product" && cta?.href) {
    return (
      <div
        css={[styles[`image_${layoutMap}`], styles[`image_${alignment}`]]}
        className="cta-section-image"
      >
        <Link to={cta?.href}>{renderImage(image)}</Link>
      </div>
    );
  }
  return (
    <div
      css={[styles[`image_${layoutMap}`], styles[`image_${alignment}`]]}
      className="cta-section-image"
    >
      {renderImage(image)}
    </div>
  );
};

export default CTAImage;
