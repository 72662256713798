import React, { useState, useEffect, Fragment } from "react";
import { Link } from "gatsby";

import { trackPlayButtonEvent } from "../../helpers/trackingEvents";
import useIsPlayerLoading from "../../hooks/useIsPlayerLoading";
import { usePlayer } from "../../contexts/PlayerContextV2/PlayerContextV2";
import { PlayerActionTypes } from "../../contexts/PlayerContextV2/interfaces/actions";
import { getEpisodePath } from "../../helpers/getEpisodePath";
import { handleEpisodePlay } from "../Player/components/helpers";

import PlayButton from "../Player/components/PlayButton";
import Heading from "../Heading";
import EpisodeImageTile from "./components/EpisodeImageTile";
import IconsBar from "./components/IconsBar";

import { EpisodeProps } from "./props";
import { styles } from "./styles";
import EpisodeDescription from "./components/EpisodeDescription";

const Episode: React.FC<EpisodeProps> = ({
  episode,
  displayDescription = true
}) => {
  const [isEpPlaying, setIsEpPlaying] = useState<boolean | null>(false);
  const {
    state: { currentPlaying, isPlaying },
    dispatch
  } = usePlayer();

  const {
    title,
    description,
    formatted_summary,
    media_url,
    season
  } = episode.attributes;

  const isLoading = useIsPlayerLoading(episode.id);
  const episodePath = getEpisodePath(episode);

  useEffect(() => {
    setIsEpPlaying(
      isPlaying && currentPlaying && currentPlaying.episode.id === episode.id
    );
  }, [isPlaying, episode, currentPlaying]);

  const handlePlayButton = () => {
    dispatch({
      type: PlayerActionTypes.SetCurrentPlaying,
      payload: {
        episode,
        episodePath
      }
    });
    handleEpisodePlay(dispatch, !isEpPlaying);
    trackPlayButtonEvent(!isEpPlaying, currentPlaying);
  };

  return (
    <article css={styles.container}>
      <div css={styles.tile}>
        <EpisodeImageTile image={episode.image} season={season} />
        <div css={styles.playButtonSmall}>
          <PlayButton
            size="large"
            isPlaying={isEpPlaying || false}
            handleClick={handlePlayButton}
            isLoading={isLoading || false}
            currentPlaying={currentPlaying}
          />
        </div>
      </div>
      <div css={styles.episodeContent}>
        <Heading headingLevel="h3" styles={styles.title}>
          <Link to={episodePath} title={title}>
            {title}
          </Link>
        </Heading>
        {displayDescription && (
          <Fragment>
            <EpisodeDescription
              season={season}
              formatted_summary={formatted_summary}
              description={description}
            />
          </Fragment>
        )}

        <footer>
          <IconsBar
            title={title}
            mediaUrl={media_url}
            episodePath={episodePath}
          />
        </footer>
      </div>

      <div css={styles.playButtonLarge}>
        <PlayButton
          size="large"
          isPlaying={isEpPlaying || false}
          handleClick={handlePlayButton}
          isLoading={isLoading || false}
          currentPlaying={currentPlaying}
        />
      </div>
    </article>
  );
};

export default Episode;
