import { Link } from "gatsby";
import React from "react";
import { getUrlObject } from "../../../helpers/getUrlObject";
import { CTAInterface } from "../";

interface CTALinkProps {
  cta: Omit<CTAInterface, "content">;
}

const CTALink: React.FC<CTALinkProps> = ({ children, cta }) => {
  const { isInternal, href } = getUrlObject(cta.href);
  if (isInternal) {
    return (
      <Link to={href} title={cta.title}>
        {children}
      </Link>
    );
  }
  return (
    <a
      href={href}
      title={cta.title}
      target={cta.target || !isInternal ? "_blank" : "_self"}
    >
      {children}
    </a>
  );
};

export default CTALink;
