import { useEffect, useState } from "react";
import compareAsc from "date-fns/compareAsc";
import useIsMountedRef from "use-is-mounted-ref";
import { EpisodeListProps, EpisodeListQueryData } from "..";
import {
  establishSeasons,
  filterAndSortEpisodes,
  sortAndFilterEpisodes
} from "../helpers/episodeListHelpers";
import { SeasonTabLabel } from "../components/SeasonTabButtons";

interface UseEpisodeListProps
  extends Pick<
    EpisodeListProps,
    "episodes" | "displayBySeason" | "seasonTabs" | "defaultSeason"
  > {
  data: EpisodeListQueryData;
}

export const useEpisodeList = ({
  data,
  episodes,
  displayBySeason,
  seasonTabs,
  defaultSeason
}: UseEpisodeListProps) => {
  const podcastEpisodes = (data || {})?.podcasts?.nodes;
  const mounted = useIsMountedRef();

  const [episodeList, setEpisodeList] = useState(episodes || podcastEpisodes);
  const seasons = establishSeasons(
    podcastEpisodes,
    Boolean(displayBySeason),
    seasonTabs
  );
  const [selectedSeason, setSelectedSeason] = useState<SeasonTabLabel>(
    defaultSeason ?? "all"
  );

  useEffect(() => {
    setEpisodeList(episodes || podcastEpisodes);
  }, [episodes, podcastEpisodes]);

  useEffect(() => {
    if (selectedSeason && displayBySeason && seasons > 1) {
      const episodesToFilter = episodes || podcastEpisodes;
      if (selectedSeason === "all") {
        const sortedEps = episodesToFilter
          .sort((a, b) => {
            return compareAsc(
              new Date(a.attributes.published_at),
              new Date(b.attributes.published_at)
            );
          })
          .filter((ep) => ep.attributes.status === "published");
        setEpisodeList(sortedEps);
        return;
      }
      if (selectedSeason === "latest") {
        const sortedEps = sortAndFilterEpisodes(episodesToFilter);
        setEpisodeList(sortedEps);
        return;
      }
      const filteredEps = filterAndSortEpisodes(
        episodesToFilter,
        selectedSeason
      );
      setEpisodeList(filteredEps);
    }
  }, [mounted, selectedSeason]);

  return {
    episodeList,
    selectedSeason,
    setSelectedSeason,
    seasons
  };
};
