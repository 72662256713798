import React from "react";
import { FiSearch } from "@react-icons/all-files/fi/FiSearch";
import { navigate } from "gatsby";
import { trackSeriesTab } from "../../../helpers/trackingEvents";
import Button from "../../Button";
import { episodeListStyles as styles } from "../styles";

export type SeasonTabLabel = number | "all" | "latest";
export interface SeasonTabsButtonsProps {
  seasons: number;
  onClick: (tab: SeasonTabLabel) => void;
  selectedSeason: SeasonTabLabel;
}

const SeasonTabButtons: React.FC<SeasonTabsButtonsProps> = ({
  seasons,
  selectedSeason,
  onClick
}) => {
  const tabsArray = Array.from({ length: seasons }, (_, i) => i + 1);
  return (
    <div css={styles.tabButtonContainer}>
      <Button
        type={selectedSeason === "all" ? "primary" : "secondary"}
        onClick={() => {
          onClick("all");
          trackSeriesTab("All Episodes");
        }}
        cssStyles={selectedSeason ? styles.activeButton : {}}
        active={selectedSeason === "all"}
      >
        All <span css={styles.episodesString}>Episodes</span>
      </Button>
      {tabsArray.map((tab) => {
        return (
          <Button
            type={tab === selectedSeason ? "primary" : "secondary"}
            onClick={() => {
              onClick(tab);
              trackSeriesTab(`Series ${tab}`);
            }}
            key={`seasonButton_${tab}`}
            cssStyles={selectedSeason ? styles.activeButton : {}}
            active={selectedSeason === tab}
          >
            Series {tab}
          </Button>
        );
      })}
      <Button
        type={selectedSeason === "latest" ? "primary" : "secondary"}
        onClick={() => {
          onClick("latest");
          trackSeriesTab("Latest Episodes");
        }}
        cssStyles={selectedSeason ? styles.activeButton : {}}
        active={selectedSeason === "latest"}
      >
        Latest
      </Button>
      <Button
        type="secondary"
        onClick={() => {
          trackSeriesTab("Search");
          navigate("/search");
        }}
        aria-label="Search"
      >
        <span css={styles.searchSpan}>Search</span>
        <FiSearch css={styles.searchIcon} aria-label="season tab search button" />
      </Button>
    </div>
  );
};

export default SeasonTabButtons;
