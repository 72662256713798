import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Flipped } from "react-flip-toolkit";

import Inner from "../Inner";
import Heading from "../Heading";
import Episode from "../Episode";
import SeasonTabButtons, {
  SeasonTabLabel
} from "./components/SeasonTabButtons";

import { TransistorEpisodeInterface } from "../../interfaces/podcasts";
import { NodesInterface } from "../../interfaces/blogPost";

import { episodeListStyles as styles } from "./styles";
import setTestId from "../../helpers/setTestId";
import { useEpisodeList } from "./hooks/useEpisodeList";
import slugify from "slugify";

export interface EpisodeListProps {
  episodes?: Array<TransistorEpisodeInterface>;
  collapseBottom?: boolean;
  title?: string;
  seasonTabs?: number;
  displayDescriptions?: boolean;
  collapsePadding?: boolean;
  displayBySeason?: boolean;
  defaultSeason?: SeasonTabLabel;
  hideTabs?: boolean;
}

export interface EpisodeListQueryData {
  podcasts: NodesInterface<TransistorEpisodeInterface>;
}

const EpisodeList: React.FC<EpisodeListProps> = ({
  episodes,
  collapseBottom,
  title,
  seasonTabs,
  displayDescriptions = true,
  collapsePadding = false,
  displayBySeason,
  defaultSeason,
  hideTabs
}) => {
  const data: EpisodeListQueryData = useStaticQuery(graphql`
    query EpisodesQuery {
      podcasts: allTransistorEpisode {
        nodes {
          id
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          ...TransistorEpisodeAttributes
        }
      }
    }
  `);

  const {
    episodeList,
    selectedSeason,
    setSelectedSeason,
    seasons
  } = useEpisodeList({
    data,
    episodes,
    displayBySeason,
    seasonTabs,
    defaultSeason
  });

  if (episodeList.length === 0 && !selectedSeason) {
    return null;
  }

  return (
    <div css={styles.container}>
      <Inner
        cssStyles={[
          collapseBottom ? styles.innerBottomCollapsed : styles.innerBottom
        ]}
        collapseAll={collapsePadding}
      >
        {title && (
          <Heading headingLevel="h2" id={slugify(title)}>
            {title}
          </Heading>
        )}
        {!hideTabs && (
          <SeasonTabButtons
            seasons={seasons}
            selectedSeason={selectedSeason}
            onClick={(tabs) => setSelectedSeason(tabs)}
          />
        )}
        <ul css={styles.list}>
          {episodeList.map((episode, i: number) => {
            return (
              <Flipped key={i} flipId={episode.id}>
                <li
                  css={[styles.listItem, i === 0 && styles.listItemFirst]}
                  {...setTestId("episode-item")}
                >
                  <Episode
                    episode={episode}
                    episodeNumber={i}
                    displayDescription={displayDescriptions}
                  />
                </li>
              </Flipped>
            );
          })}
        </ul>
      </Inner>
    </div>
  );
};

export default EpisodeList;
