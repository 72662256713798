import { EmotionStyles } from "../../../interfaces/emotion";
import { breakpointMixins } from "../../../styles/breakpoints";
import { colors } from "../../../styles/colors";
import { variables } from "../../../styles/variables";

const { spacing } = variables;

export const episodeListStyles: EmotionStyles = {
  innerBottom: {
    [breakpointMixins.md]: {
      paddingBottom: 80
    }
  },
  innerBottomCollapsed: {
    paddingBottom: 0
  },
  innerCollapsePadding: {
    padding: 0
  },
  tabButtonContainer: {
    "& button": {
      marginRight: spacing.small,
      marginBottom: spacing.small
    }
  },
  list: {
    listStyleType: "none",
    padding: 0
  },
  listItem: {
    padding: `${spacing.large} 0`,
    borderTop: `1px solid ${colors.lightGrey}`
  },
  listItemFirst: {
    borderTopWidth: 0,
    paddingTop: spacing.medium
  },
  activeButton: {
    cursor: "default"
  },
  episodesString: {
    display: "none",
    [breakpointMixins.md]: {
      display: "inline"
    }
  },
  searchSpan: {
    display: "none",
    [breakpointMixins.ms]: {
      display: "inline"
    }
  },
  searchIcon: {
    marginBottom: -2,
    [breakpointMixins.ms]: {
      display: "none"
    }
  }
};
